import React, { useEffect, useState } from 'react';
import './CustomerCashback.scss';
import { fetchData } from '../../../requests/requests';
import environment from '../../../enviroment/enviroment';

const CustomerCashback = () => {
    const [cashbackData, setCashbackData] = useState([]);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem('user'))?.['access_token']);

        const validateToken = async () => {
            setLoading(true);
            try {
                if (token) {
                    const response = await fetchData(
                        environment.api.private_api_url,
                        '/cashback/get_cashback',
                        {},
                        '',
                        token
                    );
                    if (response) {
                        setCashbackData(response.data);
                    } else {
                        console.error('Unexpected response format:', response);
                        setCashbackData([]);  // Default to an empty array if response isn't an array
                    }
                }
            } catch (error) {
                console.error('Error validating token:', error);

                if (error.message && error.message.includes('Token has expired')) {
                    localStorage.clear();
                    setToken(null);
                }
            } finally {
                setLoading(false);
            }
        };

        validateToken();
    }, [token]);

    return (
        <div className="customer-cashback-container">
            {loading ? (
                <p>Loading...</p>
            ) : token ? (
                cashbackData.length > 0 ? (
                    cashbackData.map((cashback, index) => (
                        <div key={index} className="customer-cashback-card">
                            <p className="customer-cashback-card-name">{cashback.comercio || 'N/A'}</p>
                            <p>Cashback: ${cashback.balance || 0}</p>
                            <p>Proximo en vencer: {cashback.proxima_fecha_vencimiento?(cashback.proxima_fecha_vencimiento?._Date__year + '-' + cashback.proxima_fecha_vencimiento?._Date__month + '-' + cashback.proxima_fecha_vencimiento?._Date__day):'no disponible'}</p>
                        </div>
                    ))
                ) : (
                    <p>No cashback data available.</p>
                )
            ) : (
                <p>Token is invalid. Please log in again.</p>
            )}
        </div>
    );
};

export default CustomerCashback;
